<template>
  <b-modal aria-modal has-modal-card trap-focus
    aria-role="dialog"
    class="kst-modal-input"
    :active="isActive"
    :class="theClass"
    @close="handleCancel"
  >
    <div class="modal-card ks-max-width-100p mb-5" :style="'width: ' + width + 'px'">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ theTitle }}</p>
        <button class="delete" @click="handleCancel"/>
      </header>

      <section class="modal-card-body">
        <template v-if="defaultSlotVisible">
          <ValidationObserver ref="formModalInput">
            <slot />
          </ValidationObserver>
        </template>

        <kst-errors class="pt-4" :items="errors"/>
        <kst-failure save-mode :items="failure"/>
      </section>

      <footer class="modal-card-foot is-justify-content-end">
        <template v-if="buttonsAreaVisible">
          <div class="buttons">
            <kst-button close-mode class="ks-button" @click="handleCancel"/>
            <kst-button submit-mode @click="handleSubmit"/>
          </div>
        </template>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import RouterModel from "../../../router/RouterModel.js";

/**
 * flow (how to use):
 * 1. handle Init event, set search-data and do show when needed
 * 2. handle Submit event and do saveData (as needed)
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, SnackbarMixin],
  props: {
    module: Object,
    moduleType: Object,
    title: String,
    width: Number,
    // status
    hideFormSuccess: Boolean
  },
  data: () => ({
    isActive: false,
    // loading
    detailsLoading: null,
    saveLoading: null,
    // area
    buttonsAreaVisible: false,
    // support debounce
    loadCounter: 0,
    // slot
    defaultSlotVisible: false,
    // error
    errors: null,
    failure: null
  }),
  computed: {
    theClass() {
      return this.title
        ? undefined
        : this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    },
    theTitle() {
      return this.title
        ? this.title
        : RouterModel.getNavSubTitle(this.module, this.moduleType);
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.init_Data();
      this.$emit(this.$kst.Enum.Event.Init, this.show, this.hide, 
        this.resetFailure
      );
    },
    init_Data() {
      this.isActive = false;
      // loading
      this.detailsLoading = null;
      this.saveLoading = null;
      // area
      this.buttonsAreaVisible = true;
      // support debounce
      this.loadCounter = 0;
      // slot
      this.defaultSlotVisible = false;
      // error
      this.errors = null;
      this.failure = null;
    },

    handleCancel() {
      this.isActive = false;
    },
    handleSubmit() {
      this.$refs.formModalInput.validate().then(isValid => {
        if (isValid) {
          this.$emit(this.$kst.Enum.Event.Submit, this.saveData);
        }
        else {
          this.notifyFormError();
        }
      });
    },

    saveData(config, successCallback) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      this.runAxios(config, successCallback,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    saveData_Success(data, successCallback) {
      this.saveData_End();
      this.buttonsAreaVisible = false;
      successCallback(data);

      if (!this.hideFormSuccess) {
        this.notifyFormSuccess();
      }
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = message;
      this.saveData_End();
      this.notifyFormError();
    },

    resetFailure() {
      this.errors = null;
      this.failure = null;
    },
    show() {
      this.isActive = true;
      this.defaultSlotVisible = true;
    },
    hide() {
      this.isActive = false;
    }
  }
}
</script>