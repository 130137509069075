import App from "../../services/App/App.js";
import WarehouseModel from "../Warehouse/WhsModel.js";
import ModelBody from "./StopModel_Body.js";
import ModelDetails from "./StopModel_Details.js";
import ModelNew from "./StopModel_New.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  StockOpnameItem: ModelBody.StockOpnameItem,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createItem() {
    return ModelBody.createItem();
  },

  setItemErrors(item, field, errors) {
    item.Errors = App.Vee.getRowErrors(item, field, errors);
  },

  updateItem(item) {
    ModelBody.updateItem(item);
  },
  updateItemByPackaging(item) {
    ModelBody.updateItemByPackaging(item);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  validateItems(userItems, row) {
    return ModelBody.validateItems(userItems, row);
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Details ***/

  Details: {
    getActions(id, access) {
      return App.Access.getDetailsAvailableActions(
        id, ModelBody.Actions.Details, ModelBody.Module, access
      );
    },
    getItemInfo(userItem) {
      return ModelDetails.getItemInfo(userItem);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_PrintDetails;
    }
  },

  /*** method - New ***/

  New:{
    getItemInfo(item) {
      return ModelNew.getItemInfo(item);
    },

    setDetailsByWarehouseList(userData, warehouseList) {
      ModelNew.setDetailsByWarehouseList(userData, warehouseList);
    },
    setItemByStock(item, stockData, itemData) {
      ModelNew.setItemByStock(item, stockData, itemData);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}