<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../PurCatalog.js";
import Info from "../PurInfo.js";
import Model from "../PurModel.js";
import PurItemCatalog from "../PurItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },
    addList(purchaseList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.VendorID.Label);
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader(Catalog.VendorAddress);
      this.addColumnHeader(Catalog.DeliveryAddress);
      this.addColumnHeader(Catalog.DeliveryDate);
      if (this.access.Fld_Amount) {
        this.addColumnHeader(Catalog.Total);
      }
      this.addColumnHeader(Catalog.Status);

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(PurItemCatalog.DispatchID.Label);
        this.addColumnHeader(PurItemCatalog.DispatchID);
        this.addColumnHeader(PurItemCatalog.Packaging);
        this.addColumnHeader(PurItemCatalog.Quantity);
        this.addColumnHeader(PurItemCatalog.TotalQuantity);
        if (this.access.Fld_Amount) {
          this.addColumnHeader(PurItemCatalog.BuyPrice);
          this.addColumnHeader(PurItemCatalog.ExpenseAmount);
        }
        this.addColumnHeader(PurItemCatalog.IsClosed);
      }

      // value
      for (const row of purchaseList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("PONumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("VendorID", row, Catalog);
        this.addCellRelatedValue("VendorID", row, Catalog);
        this.addCellValue("VendorAddress", row, Catalog);
        this.addCellValue("DeliveryAddress", row, Catalog);
        this.addCellValue("DeliveryDate", row, Catalog);
        if (this.access.Fld_Amount) {
          this.addCellValue("Total", row, Catalog);
        }
        this.addCellStatus(row.Status, Model.Status);

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("DispatchID", row, PurItemCatalog);
          this.addCellRelatedValue("DispatchID", row, PurItemCatalog);
          this.addCellValue("Packaging", row, PurItemCatalog);
          this.addCellValue("Quantity", row, PurItemCatalog);
          this.addCellValue("TotalQuantity", row, PurItemCatalog);
          if (this.access.Fld_Amount) {
            this.addCellValue("BuyPrice", row, PurItemCatalog);
            this.addCellValue("ExpenseAmount", row, PurItemCatalog);
          }
          this.addCellValue("IsClosed", row, PurItemCatalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>