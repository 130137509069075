<template>
  <div class="columns">
    <div class="column is-half">
      <kst-section class="is-half" :title="InvRecPayCatalog.InvoiceRecTotal.Label">
        <template #top-right>
          <kst-title>{{ recTotal }}</kst-title>
        </template>
        <kst-divider columns-mode/>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvRecPayCatalog.InvoiceTotal.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="InvoiceTotal" :data="recData" :catalog="InvRecPayCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvRecPayCatalog.RtnInvoiceTotal.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="RtnInvoiceTotal" :data="recData" :catalog="InvRecPayCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvRecPayCatalog.RecPayTotal.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="RecPayTotal" :data="recData" :catalog="InvRecPayCatalog"/>
            </div>
          </div>
        </div>
        <kst-divider columns-mode/>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvRecPayCatalog.InvoiceCreditAmount.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="InvoiceCreditAmount" :data="recData" :catalog="InvRecPayCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvRecPayCatalog.InvoiceDueDate.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="InvoiceDueDate" :data="recData" :catalog="InvRecPayCatalog"/>
            </div>
          </div>
        </div>
      </kst-section>
    </div>
    <div class="column is-half is-hidden-mobile"/>
  </div>
</template>

<script>
import InvRecPayCatalog from "../InvRecPayCatalog.js";

export default {
  props: {
    recData: Object
  },
  data: () => ({
    InvRecPayCatalog: InvRecPayCatalog
  }),
  computed: {
    recTotal() {
      return this.$kst.Value.getValue("InvoiceRecTotal", this.recData, InvRecPayCatalog);
    }
  }
}
</script>