import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: AppModule.Item.FullName,
    ColumnLabel: AppCatalog.Field.Name.Label,
    ExportLabel: AppCatalog.Field.Name.Label,
    PrintLabel: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.ID.Type,
    Required: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  HppTotal: {
    Label: AppCatalog.Field.HppTotal.Label,
    Type: AppCatalog.Field.HppTotal.Type,
    Output: AppCatalog.Field.HppTotal.Output,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly
  },
  PackagingName: {
    Label: AppModule.ItemPackaging.FullName,
    Type: InputType.Select,
    Required: true
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label + " Saat Ini",
    Type: AppCatalog.Field.Qty.Type,
    MinValue: 0,
    MaxLength: AppCatalog.Field.Qty.MaxLength,
    Required: true
  },
  TotalQty: {
    Label: AppCatalog.Field.TotalQty.Label,
    Type: AppCatalog.Field.TotalQty.Type,
    ReadOnly: AppCatalog.Field.TotalQty.ReadOnly
  },

  // support
  DispatchSellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    Output: AppCatalog.Field.SellPrice.Output,
    ReadOnly: true
  },
  Packaging: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true
  },
  Stock: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: AppCatalog.Field.Stock.ReadOnly
  }
}