export default {
  All_Glob_HPP: 12,

  // alphabetical order
  Access_Act_Details: 51,
  Access_Act_Edit: 52,
  Access_Act_List: 45,
  Access_Act_New: 53,
  AutoNumber_Act_List: 46,
  Client_Act_Details: 95,
  Client_Act_Edit: 94,
  Client_Act_ExportDetails: 96,
  Client_Act_ExportList: 92,
  Client_Act_PrintList: 221,
  Client_Act_List: 41,
  Client_Act_New: 93,
  Client_Sect_SelfService: 146,
  Company_Act_Details: 43,
  Company_Act_Edit: 54,
  DailyReport_Act_Export: 115,
  DailyReport_Act_Print: 123,
  DailyReport_Act_Report: 21,
  Dashboard_Act_Dashboard: 16,
  Dashboard_Sect_BestSellingItems: 136,
  Dashboard_Sect_DeathStock: 147,
  Dashboard_Sect_Debt: 183,
  Dashboard_Sect_DebtOverdue: 184,
  Dashboard_Sect_Invoice: 137,
  Dashboard_Sect_LowStock: 233,
  Dashboard_Sect_ProfitLoss: 139,
  Dashboard_Sect_Rec: 165,
  Dashboard_Sect_RecOverdue: 166,
  Dashboard_Sect_Stock: 138,
  Dashboard_Sect_StockWithoutInvoice: 148,
  Dashboard_Sect_TopBuyer: 140,
  Debt_Act_ExportList: 174,
  Debt_Act_List: 172,
  Debt_Act_PrintList: 173,
  DebtCard_Act_Export: 171,
  DebtCard_Act_Print: 170,
  DebtCard_Act_Report: 169,
  DebtPay_Act_Details: 177,
  DebtPay_Act_EditStatus: 182,
  DebtPay_Act_ExportDetails: 181,
  DebtPay_Act_ExportList: 180,
  DebtPay_Act_List: 175,
  DebtPay_Act_New: 176,
  DebtPay_Act_PrintDetails: 178,
  DebtPay_Act_PrintList: 179,
  Delivery_Act_Details: 89,
  Delivery_Act_EditStatus: 91,
  Delivery_Act_ExportList: 200,
  Delivery_Act_List: 38,
  Delivery_Act_New: 88,
  Delivery_Act_PrintList: 209,
  Delivery_Act_PrintSJ: 90,
  ExpenseCategory_Act_Details: 185,
  ExpenseCategory_Act_Edit: 168,
  ExpenseCategory_Act_List: 47,
  ExpenseCategory_Act_New: 167,
  Expenses_Act_Details: 55,
  Expenses_Act_Edit: 56,
  Expenses_Act_ExportDetails: 131,
  Expenses_Act_ExportList: 132,
  Expenses_Act_List: 24,
  Expenses_Act_New: 57,
  Expenses_Act_PrintList: 211,
  HppAdj_Act_Details: 106,
  HppAdj_Act_ExportList: 201,
  HppAdj_Act_List: 30,
  HppAdj_Act_New: 145,
  HppAdj_Act_PrintDetails: 130,
  HppAdj_Act_PrintList: 225,
  Invoice_Act_Details: 4,
  Invoice_Act_Edit: 5,
  Invoice_Act_EditDate: 230,
  Invoice_Act_ExportDetails: 11,
  Invoice_Act_ExportList: 7,
  Invoice_Act_List: 2,
  Invoice_Act_New: 3,
  Invoice_Act_PrintDetails: 8,
  Invoice_Act_PrintFaktur: 9,
  Invoice_Act_PrintList: 207,
  Invoice_Act_PrintThermal: 10,
  Invoice_Tab_Profit: 1,
  Item_Act_Delete: 112,
  Item_Act_Details: 70,
  Item_Act_Edit: 69,
  Item_Act_ExportDetails: 71,
  Item_Act_ExportList: 73,
  Item_Act_List: 25,
  Item_Act_New: 68,
  Item_Act_PrintBarcode: 72,
  Item_Act_PrintList: 105,
  Item_Stock_Act_Export: 66,
  Item_Stock_Act_List: 65,
  Item_Stock_Act_Print: 67,
  LowStock_Act_List: 232,
  Omzet_Act_Export: 121,
  Omzet_Act_Print: 122,
  Omzet_Act_Report: 19,
  PaymentType_Act_Edit: 50,
  PaymentType_Act_List: 49,
  Pos_Act_New: 23,
  ProfitLoss_Act_Export: 114,
  ProfitLoss_Act_Print: 196,
  ProfitLoss_Act_Report: 18,
  Purchase_Act_Delete: 236,
  Purchase_Act_Details: 98,
  Purchase_Act_Edit: 99,
  Purchase_Act_ExportList: 101,
  Purchase_Act_ExportDetails: 102,
  Purchase_Act_List: 33,
  Purchase_Act_New: 100,
  Purchase_Act_PrintDetails: 103,
  Purchase_Act_PrintList: 195,
  Purchase_Fld_Amount: 104,
  Rec_Act_ExportList: 157,
  Rec_Act_List: 155,
  Rec_Act_PrintList: 156,
  RecCard_Act_Export: 154,
  RecCard_Act_Print: 153,
  RecCard_Act_Report: 152,
  RecPay_Act_Details: 160,
  RecPay_Act_ExportDetails: 164,
  RecPay_Act_ExportList: 163,
  RecPay_Act_List: 158,
  RecPay_Act_New: 159,
  RecPay_Act_PrintDetails: 161,
  RecPay_Act_PrintList: 162,
  Receive_Act_Details: 64,
  Receive_Act_ExportDetails: 149,
  Receive_Act_ExportList: 151,
  Receive_Act_Edit: 63,
  Receive_Act_EditDate: 231,
  Receive_Act_List: 34,
  Receive_Act_New: 62,
  Receive_Act_PrintDetails: 150,
  Receive_Act_PrintList: 210,
  Receive_Fld_Amount: 13,
  ReportStockAdj_Act_Report: 22,
  RtnInvoice_Act_Details: 87,
  RtnInvoice_Act_Edit: 86,
  RtnInvoice_Act_ExportList: 204,
  RtnInvoice_Act_List: 37,
  RtnInvoice_Act_New: 6,
  RtnInvoice_Act_PrintDetails: 238,
  RtnInvoice_Act_PrintList: 208,
  RtnInvoice_Act_PrintThermal: 237,
  RtnReceive_Act_Details: 217,
  RtnReceive_Act_Edit: 216,
  RtnReceive_Act_List: 214,
  RtnReceive_Act_New: 215,
  RtnReceive_Act_PrintDetails: 239,
  RtnReceive_Act_PrintList: 218,
  RtnReceive_Act_ExportList: 219,
  Sales_Act_Delete: 235,
  Sales_Act_Details: 82,
  Sales_Act_Edit: 81,
  Sales_Act_ExportDetails: 83,
  Sales_Act_ExportList: 85,
  Sales_Act_List: 35,
  Sales_Act_New: 36,
  Sales_Act_PrintDetails: 84,
  Sales_Act_PrintList: 197,
  SalesRecap_Act_Details: 107,
  SalesRecap_Act_ExportDetails: 109,
  SalesRecap_Act_ExportList: 229,
  SalesRecap_Act_List: 39,
  SalesRecap_Act_New: 108,
  SalesRecap_Act_PrintDetails: 110,
  SalesRecap_Act_PrintList: 228,
  SpecialPrice_Act_Details: 127,
  SpecialPrice_Act_Edit: 128,
  SpecialPrice_Act_List: 48,
  SpecialPrice_Act_New: 129,
  SpecialPriceAdj_Act_Details: 124,
  SpecialPriceAdj_Act_ExportList: 202,
  SpecialPriceAdj_Act_List: 32,
  SpecialPriceAdj_Act_New: 125,
  SpecialPriceAdj_Act_PrintDetails: 126,
  SpecialPriceAdj_Act_PrintList: 227,
  StockAvailability_Act_List: 31,
  StockCard_Act_Export: 74,
  StockCard_Act_Print: 186,
  StockCard_Act_Report: 17,
  StockClosing_Act_Details: 141,
  StockClosing_Act_ExportDetails: 142,
  StockClosing_Act_ExportList: 205,
  StockClosing_Act_List: 29,
  StockClosing_Act_New: 143,
  StockClosing_Act_PrintDetails: 144,
  StockClosing_Act_PrintList: 224,
  StockConv_Act_Details: 188,
  StockConv_Act_ExportDetails: 189,
  StockConv_Act_ExportList: 190,
  StockConv_Act_List: 187,
  StockConv_Act_New: 191,
  StockConv_Act_PrintDetails: 192,
  StockConv_Act_PrintList: 193,
  StockOpname_Act_Details: 76,
  StockOpname_Act_ExportList: 198,
  StockOpname_Act_List: 26,
  StockOpname_Act_New: 75,
  StockOpname_Act_PrintDetails: 79,
  StockOpname_Act_PrintItems: 111,
  StockOpname_Act_PrintList: 194,
  StockReduction_Act_Details: 113,
  StockReduction_Act_ExportDetails: 134,
  StockReduction_Act_ExportList: 203,
  StockReduction_Act_List: 27,
  StockReduction_Act_New: 133,
  StockReduction_Act_PrintDetails: 135,
  StockReduction_Act_PrintList: 223,
  StockTrf_Act_Details: 78,
  StockTrf_Act_ExportList: 199,
  StockTrf_Act_List: 28,
  StockTrf_Act_New: 77,
  StockTrf_Act_PrintDetails: 80,
  StockTrf_Act_PrintList: 212,
  Transaction_Act_Export: 97,
  Transaction_Act_Report: 20,
  Transaction_Act_Print: 222,
  User_Act_Details: 58,
  User_Act_EditPassword: 213,
  User_Act_Edit: 59,
  User_Act_List: 44,
  User_Act_New: 60,
  Vendor_Act_Details: 119,
  Vendor_Act_Edit: 118,
  Vendor_Act_ExportDetails: 120,
  Vendor_Act_ExportList: 116,
  Vendor_Act_List: 40,
  Vendor_Act_New: 117,
  Vendor_Act_PrintList: 220,
  Vendor_Tab_VendorItem: 234,
  Warehouse_Act_Details: 61,
  Warehouse_Act_Edit: 15,
  Warehouse_Act_ExportList: 206,
  Warehouse_Act_List: 42,
  Warehouse_Act_New: 14,
  Warehouse_Act_PrintList: 226
}