import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import RcpyD from "./Details/RcpyD.vue";
import RcpyExD from "./ExportDetails/RcpyExD.vue";
import RcpyExL from "./ExportList/RcpyExL.vue";
import RcpyL from "./List/RcpyL.vue";
import RcpyN from "./New/RcpyN.vue";
import RcpyPD from "./PrintDetails/RcpyPD.vue";
import RcpyPL from "./PrintList/RcpyPL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.RecPay, AppModuleType.Details),
    ExportDetails: RouterModel.getPath(AppModule.RecPay, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.RecPay, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.RecPay, AppModuleType.List),
    New: RouterModel.getPath(AppModule.RecPay, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.RecPay, AppModuleType.PrintDetails),
    PrintList: RouterModel.getPath(AppModule.RecPay, AppModuleType.PrintList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.RecPay, AppModuleType.Details),
        name: RouterModel.getName(AppModule.RecPay, AppModuleType.Details),
        component: RcpyD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RecPay, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.RecPay),
          moduleattr: AppModuleAttr.RecPay_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.RecPay, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.RecPay, AppModuleType.ExportDetails),
        component: RcpyExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RecPay, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.RecPay),
          moduleattr: AppModuleAttr.RecPay_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.RecPay, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.RecPay, AppModuleType.ExportList),
        component: RcpyExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RecPay, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.RecPay),
          moduleattr: AppModuleAttr.RecPay_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.RecPay, AppModuleType.List),
        name: RouterModel.getName(AppModule.RecPay, AppModuleType.List),
        component: RcpyL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RecPay, AppModuleType.List),
          module: RouterModel.getModule(AppModule.RecPay),
          moduleattr: AppModuleAttr.RecPay_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.RecPay),
            moduleattr: AppModuleAttr.RecPay_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.RecPay),
            moduleattr: AppModuleAttr.RecPay_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.RecPay),
            moduleattr: AppModuleAttr.RecPay_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.RecPay, AppModuleType.New),
        name: RouterModel.getName(AppModule.RecPay, AppModuleType.New),
        component: RcpyN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RecPay, AppModuleType.New),
          module: RouterModel.getModule(AppModule.RecPay),
          moduleattr: AppModuleAttr.RecPay_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.RecPay, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.RecPay, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.RecPay, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.RecPay, AppModuleType.PrintDetails),
        component: RcpyPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RecPay, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.RecPay),
          moduleattr: AppModuleAttr.RecPay_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.RecPay, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.RecPay, AppModuleType.PrintList),
        component: RcpyPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RecPay, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.RecPay),
          moduleattr: AppModuleAttr.RecPay_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}