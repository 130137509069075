<template>
  <table class="ks-is-fullwidth">
    <template v-for="(item, index) in items">
      <tr :key="index">
        <td colspan="100">
          <kst-value field="DispatchID" :data="item" :catalog="RinvItemCatalog"/>
        </td>
      </tr>

      <tr :key="index+'a'">
        <td class="is-flex">
          <kst-value class="ks-space-right"
            field="Quantity" :data="item" :catalog="RinvItemCatalog"
          />
          <div class="ks-space-right">x</div>
          <kst-value field="SellPrice" :data="item" :catalog="RinvItemCatalog"/>
        </td>
        <td class="has-text-right">
          <kst-value field="Total" :data="item" :catalog="RinvItemCatalog"/>
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
import RinvItemCatalog from "../RinvItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    RinvItemCatalog: RinvItemCatalog
  })
}
</script>