<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../RcpyCatalog.js";
import Info from "../RcpyInfo.js";
import Model from "../RcpyModel.js";
import RcpyInvCatalog from "../RcpyInvCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.PayNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.ClientID.Label);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.PaymentTypeName);
      this.addColumnHeader(Catalog.PaymentValue);
      this.addColumnHeader(Catalog.StatusID);
      this.addColumnHeader(Catalog.VoidByID);
      this.addColumnHeader(Catalog.VoidDate);
      this.addColumnHeader(Catalog.Comment);

      if (this.userData.WithInvoiceList) {
        this.addColumnHeader_ID(RcpyInvCatalog.InvoiceID.Label);
        this.addColumnHeader(RcpyInvCatalog.InvoiceID);
        this.addColumnHeader(RcpyInvCatalog.InvoiceDate);
        this.addColumnHeader_ID(RcpyInvCatalog.InvWarehouseID.Label);
        this.addColumnHeader(RcpyInvCatalog.InvWarehouseID);
        this.addColumnHeader_ID(RcpyInvCatalog.InvClientID.Label);
        this.addColumnHeader(RcpyInvCatalog.InvClientID);
        this.addColumnHeader(RcpyInvCatalog.InvPaymentValue);
      }

      // value
      for (const row of dataList) {
        this.addRow();
        this.addCellValue_ID(row);
        this.addCellValue("PayNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("ClientID", row, Catalog);
        this.addCellRelatedValue("ClientID", row, Catalog);
        this.addCellValue("PaymentTypeName", row, Catalog);
        this.addCellValue("PaymentValue", row, Catalog);
        this.addCellStatus(row.StatusID, Model.StatusID);
        this.addCellRelatedValue("VoidByID", row, Catalog);
        this.addCellValue("VoidDate", row, Catalog);
        this.addCellValue("Comment", row, Catalog);

        if (this.userData.WithInvoiceList) {
          this.addCellValue("InvoiceID", row, RcpyInvCatalog);
          this.addCellRelatedValue("InvoiceID", row, RcpyInvCatalog);
          this.addCellValue("InvoiceDate", row, RcpyInvCatalog);
          this.addCellValue("InvWarehouseID", row, RcpyInvCatalog);
          this.addCellRelatedValue("InvWarehouseID", row, RcpyInvCatalog);
          this.addCellValue("InvClientID", row, RcpyInvCatalog);
          this.addCellRelatedValue("InvClientID", row, RcpyInvCatalog);
          this.addCellValue("InvPaymentValue", row, RcpyInvCatalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>