import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./RcpyModel.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.FullName
        }
      },
      // support
      Option: {
        Details: Model.ModuleType.Details.FullName + " " + Model.Module.FullName
      }
    },
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.RecPay.FullName
        },
        Option: {
          InvoiceList: "Dengan " + AppModule.Invoice.FullName,
          List: AppModuleType.List.FullName + " " + AppModule.RecPay.FullName
        }
      }
    }
  },
  Message: {
    New_IsVerified:
      AppCatalog.Message.NeedToVerify,
    RelatedList_NewRecPay:
      "Tidak diperkenankan menambah " + Model.Module.FullName + ", " +
      "saat lunas."
  },
  Section: {
    InvoiceList: {
      Title: AppModuleType.List.FullName + " " + Model.RecPayInvoice.Module.FullName
    }
  },
  Tabs: {
    Details: AppModuleType.Details.FullName
  }
}