<template>
  <kst-table paginated view-mode
    :action="hasAccessAction"
    :data="items"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="PayNumber"
      :label="Catalog.PayNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ClientName"
      :label="Catalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PaymentTypeName"
      :label="Catalog.PaymentTypeName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="PaymentValue"
      :label="Catalog.PaymentValue.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-status"
      field="StatusID"
      :label="Catalog.StatusID.Label"
    >
      <kst-status :value="row[column.field]" :statusEnum="Model.StatusID"/>
    </b-table-column>

    <template #action="{ row }">
      <Action :access="access" :row="row"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../RcpyCatalog.js";
import Model from "../RcpyModel.js";

import Action from "./RcpyL_List_Action.vue";

export default {
  components: {
    Action
  },
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    hasAccessAction() {
      return this.access.Act_Details ||
        this.access.Act_PrintDetails ||
        this.access.Act_ExportDetails;
    }
  }
}
</script>