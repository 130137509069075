import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  InvoiceCreditAmount: {
    Label: AppCatalog.Field.CreditAmount.Label,
    Type: AppCatalog.Field.CreditAmount.Type,
    Output: AppCatalog.Field.CreditAmount.Output,
    ReadOnly: AppCatalog.Field.CreditAmount.ReadOnly
  },
  InvoiceRecTotal: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Rec.Name,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  InvoiceDueDate: {
    Label: AppCatalog.Field.DueDate.Label,
    Type: AppCatalog.Field.DueDate.Type,
    ReadOnly: true
  },
  InvoiceTotal: {
    Label: AppModule.Invoice.FullName,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  RecPayTotal: {
    Label: AppModule.RecPay.FullName,
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  RtnInvoiceTotal: {
    Label: AppModule.RtnInvoice.FullName,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  }
}