<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../ItemCatalog.js";
import Info from "../ItemInfo.js";
import PackagingCatalog from "../PackagingCatalog.js";
import StockCatalog from "../../Stock/StkCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(Catalog.ID.Label);
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.TypeID);
      this.addColumnHeader(Catalog.SKU);
      this.addColumnHeader(Catalog.Barcode);
      this.addColumnHeader(Catalog.LimitQty);
      this.addColumnHeader(Catalog.Quantity);
      if (this.access.Glob_HPP) {
        this.addColumnHeader(StockCatalog.HppPerPcs);
        this.addColumnHeader(StockCatalog.HppTotal);
      }

      if (this.userData.WithPackaging) {
        this.addColumnHeader(PackagingCatalog.Name);
        this.addColumnHeader(PackagingCatalog.Qty);
        this.addColumnHeader(PackagingCatalog.Weight);
        this.addColumnHeader(PackagingCatalog.SellPrice);
        this.addColumnHeader(PackagingCatalog.IsActive);
        this.addColumnHeader(PackagingCatalog.IsDefaultPackaging);
        this.addColumnHeader(PackagingCatalog.IsDefaultPcs);
      } else {
        this.addColumnHeader(Catalog.DefaultPackagingName);
        this.addColumnHeader(Catalog.DefaultPackagingQty);
        this.addColumnHeader(Catalog.DefaultWeight);
        this.addColumnHeader(Catalog.DefaultSellPrice);
      }

      // value
      let isDefaultPackaging, isDefaultPcs;
      for (const row of dataList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue_ID(row);
        this.addCellValue("Name", row, Catalog);
        this.addCellRelatedValue("TypeID", row, Catalog);
        this.addCellValue("SKU", row, Catalog);
        this.addCellValue("Barcode", row, Catalog);
        this.addCellValue("LimitQty", row, Catalog);
        this.addCellValue("Quantity", row, Catalog);
        if (this.access.Glob_HPP) {
          this.addCellValue("HppPerPcs", row, StockCatalog);
          this.addCellValue("HppTotal", row, StockCatalog);
        }

        if (this.userData.WithPackaging) {
          isDefaultPackaging = row.DefaultPackagingName === row.PackagingName;
          isDefaultPcs = row.PackagingPcs === row.PackagingName;

          this.addCellRelatedValue("Name", row, PackagingCatalog);
          this.addCellRelatedValue("Qty", row, PackagingCatalog);
          this.addCellRelatedValue("Weight", row, PackagingCatalog);
          this.addCellRelatedValue("SellPrice", row, PackagingCatalog);
          this.addCellValue("PackagingIsActive", row, PackagingCatalog);
          this.addCell(isDefaultPackaging.toString().toUpperCase());
          this.addCell(isDefaultPcs.toString().toUpperCase());
        } else {
          this.addCellValue("DefaultPackagingName", row, Catalog);
          this.addCellValue("DefaultPackagingQty", row, Catalog);
          this.addCellValue("DefaultWeight", row, Catalog);
          this.addCellValue("DefaultSellPrice", row, Catalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>