<template>
  <kst-page-print-details page-footer
    v-slot="{ title }"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-one-third">
        <Client :details="details"/>
      </div>
      <div class="column is-one-third">
        <div class="label has-text-centered">{{ title }}</div>
        <Details :details="details"/>
      </div>
      <div class="column is-one-third">
        <Company showImage :data="companyData"/>
      </div>
    </div>

    <Items class="block" :details="details" :items="details.Items"/>

    <div class="columns is-mobile my-1">
      <div class="column is-narrow">
        <Sign/>
      </div>
      <div class="column">
        <Total :details="details" :companySettingData="companySettingData" />
      </div>
    </div>
  </kst-page-print-details>
</template>

<script>
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import Client from "./InvPF_Client.vue";
import Details from "./InvPF_Details.vue";
import Items from "./InvPF_Items.vue";
import Sign from "./InvPF_Sign.vue";
import Total from "./InvPF_Total.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    Company,
    Client,
    Details,
    Items,
    Sign,
    Total
  },
  data: () => ({
    companyData: {},
    companySettingData: {},
    details: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadPrint) {
      this.resetData();

      const config = Services.getPrintFakturData(id);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.companyData = data.CompanyDetails;
      this.companySettingData = data.CompanySettingDetails;
      this.details = data.Details;
      Model.setDetailsBySpecialPrice(this.details, data.SpecialPriceCount);

      for (const item of this.details.Items) {
        Model.updateItem(item);
      }
    },

    /*** custom ***/

    resetData() {
      this.companyData = {};
      this.companySettingData = {};
      this.details = {};
    }
  }
}
</script>