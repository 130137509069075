import App from "../../services/App/App.js";
import ModelBody from "./RcpyModel_Body.js";
import ModelDetails from "./RcpyModel_Details.js";
import ModelList from "./RcpyModel_List.js";
import ModelNew from "./RcpyModel_New.js";
import InvoiceEnum from "../Invoice/InvEnum.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  RecPayInvoice: ModelBody.RecPayInvoice,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  StatusID: ModelBody.StatusID,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createInvoice() {
    return ModelBody.createInvoice();
  },
  createStatusOptions(optionAllText) {
    return ModelBody.createStatusOptions(optionAllText, ModelBody.StatusID);
  },

  setDetailsByStatus(details) {
    ModelBody.setDetailsByStatus(details, ModelBody.StatusID);
  },

  populateData(userData, userInvoiceList) {
    let result = ModelBody.populateDetails(userData);
    result.InvoiceList = ModelBody.populateInvoiceList(userInvoiceList);
    return result;
  },

  /*** method - Details ***/

  Details: {
    Actions: ModelDetails.Actions
  },

  /*** method - List ***/

  List: {
    Search: ModelList.Search
  },

  /*** method - New ***/

  New: {
    setDetailsByClient(userData, clientData) {
      ModelNew.setDetailsByClient(userData, clientData);
    },
    setDetailsByPaymentList(userData, paymentList) {
      ModelNew.setDetailsByPaymentList(userData, paymentList);
    },
    setDetailsByDetailsVerified(userData) {
      ModelNew.setDetailsByDetailsVerified(userData);
    },
    setDetailsByWarehouseList(userData, warehouseList) {
      ModelNew.setDetailsByWarehouseList(userData, warehouseList);
    },

    setInvoiceByPaymentValue(userData, userInvoiceList, selectedInvoice) {
      ModelNew.setInvoiceByPaymentValue(userData, userInvoiceList,
        selectedInvoice
      );
    },
    setInvoiceErrors(userInvoice, field, errors) {
      userInvoice.Errors = App.Vee.getRowErrors(userInvoice, field, errors);
    },

    setInvoiceListBySearch(userInvoiceList, invoiceList) {
      ModelNew.setInvoiceListBySearch(userInvoiceList, invoiceList);
    },
    setInvoiceListByCheck(userData, userInvoiceList, selectedInvoiceList,
      selectedInvoice
    ) {
      ModelNew.setInvoiceListByCheck(userData, userInvoiceList,
        selectedInvoiceList, selectedInvoice
      );
    },
    setInvoiceListByCheckAll(userData, userInvoiceList) {
      ModelNew.setInvoiceListByCheckAll(userData, userInvoiceList);
    },
    setInvoiceListByUncheckAll(userData, userInvoiceList) {
      ModelNew.setInvoiceListByUncheckAll(userData, userInvoiceList);
    },

    getInvalidInvoiceTotal(userData) {
      return ModelNew.getInvalidInvoiceTotal(userData);
    },

    clearDetailsByClient(userData) {
      ModelNew.clearDetailsByClient(userData);
    },
  
    resetInvoiceTotal(userData) {
      ModelNew.resetInvoiceTotal(userData);
    },

    updateDetails(userData, userInvoiceList, selectedInvoice) {
      ModelNew.updateDetails(userData, userInvoiceList, selectedInvoice);
    }
  },

  /*** method - RelatedList ***/

  RelatedList: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_PrintDetails ||
        access.Act_ExportDetails;
    }
  },

  /*** external ***/

  InvoiceStatus: InvoiceEnum.Status,

  getPaymentOptions(paymentList, optionAllText, optionNonText, params) {
    return PaymentTypeModel.createOptions(paymentList, optionAllText,
      optionNonText, params
    );
  },

  getWarehouseOptions(warehouseList, optionAllText, optionSomeText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText, optionSomeText);
  }
}