<template>
  <table class="ks-is-fullwidth">
    <tr>
      <td>
        <kst-value field="SONumber" :data="data" :catalog="Catalog"/>
      </td>
      <td class="has-text-right">
        <kst-value field="InvoiceDate" :data="data" :catalog="Catalog"/>
      </td>
    </tr>
    <tr>
      <td>
        <kst-value field="WarehouseID" :data="data" :catalog="Catalog"/>
      </td>
      <td class="has-text-right">
        <kst-value field="CreatedByID" :data="data" :catalog="Catalog"/>
      </td>
    </tr>
  </table>
</template>

<script>
import Catalog from "../PosCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>