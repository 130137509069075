<template>
  <table class="ks-is-fullwidth">
    <tr>
      <td>
        <kst-value field="ReturnNumber" :data="details" :catalog="Catalog"/>
      </td>
      <td class="has-text-right">
        <kst-value field="Date" :data="details" :catalog="Catalog"/>
      </td>
    </tr>
    <tr>
      <td>
        <kst-value field="WarehouseID" :data="details" :catalog="Catalog"/>
      </td>
      <td class="has-text-right">
        <kst-value field="CreatedByID" :data="details" :catalog="Catalog"/>
      </td>
    </tr>
  </table>
</template>

<script>
import Catalog from "../RinvCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>