<template>
  <kst-section items-mode>
    <StockSearchListPcs sellPrice weight
      @init="handleStock_init"
      @submit="handleStock_submit"
    />

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <kst-button action-mode
          class="is-primary"
          :label="Model.StockOpnameItem.Module.FullName"
          :moduleType="Model.StockOpnameItem.ModuleType.New"
          @click="handleAdd_item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <template v-if="userData.WarehouseID !== null">
          <kst-button action-mode
            class="is-primary"
            :label="Model.StockOpnameItem.Module.FullName"
            :moduleType="Model.StockOpnameItem.ModuleType.New"
            @click="handleAdd_item"
          />
        </template>
      </template>
    </template>

    <template v-if="userData.WarehouseID !== null">
      <List :userItems="userItems"/>
    </template>
  </kst-section>
</template>

<script>
import Model from "../StopModel.js";
import Services from "../../../services/Api/StockOpnameServices.js";

import List from "./StopN_Items_List.vue";
import StockSearchListPcs from "../../Stock/SearchListPcs/StkScLPcs.vue";

export default {
  components: {
    List,
    StockSearchListPcs
  },
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    // func
    showStockFunc: null
  }),
  methods: {
    /*** by stock modal ***/

    handleStock_init(show) {
      this.showStockFunc = show;
    },
    handleStock_submit(stockData, itemData) {
      let item = Model.createItem();
      Model.New.setItemByStock(item, stockData, itemData.ItemDetails);
      Model.updateItem(item);
      this.userItems.push(item);
    },

    /*** custom ***/

    handleAdd_item() {
      this.showStockFunc(
        this.userData.WarehouseID,
        Services.getRelatedStockList,
        Services.getRelatedItemDetails
      );
    }
  }
}
</script>