<template>
  <table class="ks-is-fullwidth">
    <template v-for="(item, index) in items">
      <tr :key="index">
        <td colspan="100">
          <kst-value field="Name" :data="item" :catalog="InvItemCatalog"/>
        </td>
      </tr>

      <tr :key="index+'a'">
        <td class="is-flex">
          <kst-value class="ks-space-right"
            field="TotalRequestedQuantity" :data="item" :catalog="InvItemCatalog"
          />
          <div class="ks-space-right">x</div>
          <kst-value field="DispatchSellPrice" :data="item" :catalog="InvItemCatalog"/>
        </td>
        <td class="has-text-right">
          <kst-value field="BasicTotalPrice" :data="item" :catalog="InvItemCatalog"/>
        </td>
      </tr>

      <template v-if="item.DispatchSellPrice !== item.SellPrice">
        <tr :key="index+'b'">
          <td colspan="100">
            <div class="is-flex is-justify-content-right">
              <div class="ks-space-right">
                {{ InvItemCatalog.DiscountedValue.Label }}
              </div>
              <kst-value field="DiscountedValue" :data="item" :catalog="InvItemCatalog"/>
            </div>
          </td>
        </tr>
        <tr :key="index+'c'">
          <td colspan="100" class="has-text-right">
            <kst-value field="Price" :data="item" :catalog="InvItemCatalog"/>
          </td>
        </tr>
      </template>
    </template>
  </table>
</template>

<script>
import InvItemCatalog from "../InvItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    InvItemCatalog: InvItemCatalog
  })
}
</script>