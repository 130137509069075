<template>
  <kst-page-print-details page-footer
    class="has-text-weight-bold ks-font-monospace"
    @init="handleInit"
  >
    <template #page-footer="{ label, timestamp }">
      {{ label }} {{ timestamp }}
    </template>

    <Company class="block" :data="companyData" :customData="data"/>
    <Details :data="data"/>

    <kst-divider class="has-background-black"/>
    <Items :items="data.Items"/>

    <kst-divider class="has-background-black"/>
    <Total :data="data" :items="data.Items"/>
    <Footer :companyData="companyData"/>
  </kst-page-print-details>
</template>

<script>
import Model from "../PosModel.js";
import Services from "../../../services/Api/PosServices.js";

import Details from "./PosPT_Details.vue";
import Total from "./PosPT_Total.vue";
import Items from "./PosPT_Items.vue";
import Footer from "./PosPT_Footer.vue";
import Company from "../../Company/RelatedPrintThermal/CpPT.vue";

export default {
  components: {
    Details,
    Footer,
    Items,
    Total,
    Company
  },
  data: () => ({
    data: {},
    companyData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadPrint) {
      this.resetData();

      const config = Services.getPrintThermalData(id);
      loadPrint(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.data = data.Details;
      this.companyData = data.CompanyDetails;
      Model.updateDetailsByPrintThermal(this.data);
      Model.updateItemsByPrintThermal(this.data.Items);

      for (const item of this.data.Items) {
        Model.updateItem(item);
      }
    },

    /*** custom ***/

    resetData() {
      this.data = {};
      this.companyData = {};
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>