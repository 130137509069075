import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import ItemModel from "../Item/ItemModel.js";

export default {
  // required in model
  Module: AppModule.StockOpname,
  ModuleType: {
    Details: AppModuleType.Details,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintItems: AppModuleType.PrintItems,
    PrintList: AppModuleType.PrintList
  },

  /*** related ***/

  StockOpnameItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.StockOpname_Act_Details,
    Act_List: AppModuleAttr.StockOpname_Act_List,
    Act_New: AppModuleAttr.StockOpname_Act_New,
    Act_PrintDetails: AppModuleAttr.StockOpname_Act_PrintDetails,
    Act_PrintItems: AppModuleAttr.StockOpname_Act_PrintItems,
    Act_PrintList: AppModuleAttr.StockOpname_Act_PrintList,
    Glob_HPP: AppModuleAttr.All_Glob_HPP
  },

  Actions:{
    Details: [
      AppModuleType.PrintDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.PrintDetails
    ]
  },

  Search: {
    ListFields: ["AdjNumber"],
    ListParams: ["Search", "Date", "WarehouseID"]
  },

  /*** method ***/

  createDetails() {
    return {
      // user input
      WarehouseID: App.Session.getDefaultWarehouseID(),
      AdjNumber: "",
      IsAutoNumber: true,
      Comment: "",
      // defined by system
      Date: App.In.getDateToday()
    };
  },
  createItem() {
    return {
      // user input
      DispatchID: null,
      Qty: "",
      PackagingName: "",
      // defined by system
      DispatchName: "",
      SKU: "",
      DispatchSellPrice: "",
      Stock: 0,
      StockQty: 0,
      Packaging: "",
      PackagingValue: 1,
      PackagingOptions: null,
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },

  updateItem(item) {
    item.Packaging = App.Data.getPackaging(
      item.PackagingName, item.PackagingValue
    );
    item.Stock = App.Data.getQtyByPackaging(item.StockQty, 
      item.PackagingValue
    );
  },
  updateItemByPackaging(item) {
    if (item.PackagingOptions) {
      const packagingData = ItemModel.getPackagingData(
        item.PackagingOptions.rows, item.PackagingName
      );

      if (packagingData) {
        // defined by system
        item.PackagingValue = packagingData.Qty;
        item.DispatchSellPrice = packagingData.SellPrice;
      }
    }
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  validateItems(userItems, row) {
    for (const item of userItems) {
      if (item.DispatchID === row.ItemID) {
        return false;
      }
    }

    return true;
  },

  populateDetails(userData) {
    return {
      WarehouseID: userData.WarehouseID,
      AdjNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.AdjNumber),
      Comment: App.Out.getString(userData.Comment),
      Date: App.Out.getDateString(userData.Date)
    };
  },
  populateItems(userItems) {
    let resultItems = [];
    for (const item of userItems) {
      resultItems.push({
        DispatchID: item.DispatchID,
        DispatchName: item.DispatchName,
        PackagingName: item.PackagingName,
        PackagingValue: item.PackagingValue,
        Qty: App.Out.getInteger(item.Qty)
      });
    }

    return resultItems;
  }
}