import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.purchase;

export default {
  deleteData(id) {
    return {
      method: App.Enum.Axios.Method.Delete,
      url: apiEndpoint + "?id=" + id
    };
  },
  getDeleteData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/deletedata?id=" + id
    };
  },
  getDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata?id=" + id
    };
  },
  getEditData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdata?id=" + id
    };
  },
  getExportDetails(id, params) {
    let options = { id: id };
    options = Object.assign(options, params);
    const attributes = ["id", "withreceivelist"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportdetails" + urlParams
    };
  },
  getExportList(params) {
    const attributes = ["startdate", "enddate", "status", "vendor",
      "vendorname", "warehouse", "search", "field", "withitems"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportlist" + urlParams
    };
  },
  getList(params) {
    const attributes = ["startdate", "enddate", "status", "vendor",
      "vendorname", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["startdate", "enddate", "status", "vendor",
      "vendorname", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getNewData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata"
    };
  },
  getPrintListData(params) {
    const attributes = ["startdate", "enddate", "status", "vendor",
      "vendorname", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printlistdata" + urlParams
    }
  },
  getPrintDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printdetailsdata?id=" + id
    };
  },
  getRelatedItemDetails(itemId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/item/details?item=" + itemId
    };
  },
  getRelatedItemList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/item/list" + urlParams
    };
  },
  getRelatedReceivePriceList(params) {
    const attributes = ["item", "startdate", "enddate", "vendor",
      "vendorname","search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/receive-price/list" + urlParams
    };
  },
  getRelatedStockList(params) {
    const attributes = ["warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/stock/list" + urlParams
    };
  },
  getRelatedVendorAddressList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/vendor-address/list" + urlParams
    };
  },
  getRelatedVendorItemList(params) {
    const attributes = ["vendor"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/vendoritem/list" + urlParams
    };
  },
  getRelatedVendorList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/vendor/list" + urlParams
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  },
  putData(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint,
      data: data
    };
  },
  putPurchaseItem(purchaseItemId, ItemId) {
    const data = {
      PurchasingItemID: purchaseItemId,
      ItemID: ItemId
    };

    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/item",
      data: data
    };
  }
}