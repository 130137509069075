import App from "../../services/App/App.js";
import ItemModel from "../Item/ItemModel.js";
import StopItemCatalog from "./StopItemCatalog.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  getItemInfo(item) {
    let infoList = [];

    // SKU
    infoList.push(StopItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", item, StopItemCatalog)
    );

    // DispatchSellPrice
    infoList.push(StopItemCatalog.DispatchSellPrice.Label + ": " +
      App.Value.getValue("DispatchSellPrice", item, StopItemCatalog)
    );

    // Stock
    infoList.push(StopItemCatalog.Stock.Label + ": " +
      App.Value.getValue("Stock", item, StopItemCatalog) + " " +
      item.PackagingName
    );

    return infoList;
  },

  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },
  setItemByStock(item, stockData, itemData) {
    item.PackagingOptions = ItemModel.createPackagingActiveOptions(
      itemData.PackagingList
    );

    const firstPackaging = item.PackagingOptions.rows[0];

    // user input
    item.DispatchID = stockData.ItemID;
    item.PackagingName = firstPackaging.Name;

    // defined by system
    item.DispatchName = itemData.Name;
    item.SKU = itemData.SKU;
    item.DispatchSellPrice = firstPackaging.SellPrice;
    item.StockQty = stockData.Quantity;
    item.PackagingValue = firstPackaging.Qty;
  }
}