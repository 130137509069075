<template>
  <div class="block">
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="data"
      dateField="Date"
      numberField="PayNumber"
    />

    <template v-if="data.ClientID">
      <kst-output field="ClientID" :data="data" :catalog="Catalog">
        <template #left>
          <kst-output addons floating-label
            field="ClientAlias" :data="data" :catalog="Catalog"
          />
        </template>
        <template #default>
          <kst-output addons expanded floating-label
            field="ClientName" :data="data" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </template>
    <template v-else>
      <kst-output control-off field="ClientID" :data="data" :catalog="Catalog">
        <kst-output addons expanded floating-label
          field="ClientName" :data="data" :catalog="Catalog"
        />
      </kst-output>
    </template>

    <kst-output field="PaymentTypeName" :data="data" :catalog="Catalog"/>
    <kst-output field="PaymentValue" :data="data" :catalog="Catalog"/>
    <kst-output field="Comment" :data="data" :catalog="Catalog"/>
    <kst-output field="StatusID" :data="data" :catalog="Catalog"/>

    <template v-if="data.StatusID === Model.StatusID.Void.ID">
      <kst-output control-off field="VoidBy" :data="data" :catalog="Catalog">
        <template #default>
          <kst-output expanded floating-label
            class="pr-2"
            field="VoidByID" :data="data" :catalog="Catalog"
          />
        </template>
        <template #right>
          <kst-output addons expanded floating-label
            field="VoidDate" :data="data" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </template>

    <kst-created :data="data"/>
    <kst-output field="WarehouseID" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../RcpyCatalog.js";
import Model from "../RcpyModel.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>