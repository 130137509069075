<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../RcpyCatalog.js";
import Info from "../RcpyInfo.js";
import Model from "../RcpyModel.js";
import RcpyInvCatalog from "../RcpyInvCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();
      this.addDetails(data.Details);

      this.download(fileName);
    },

    addDetails(details) {
      this.addDetails_Details(details);
      this.addDetails_Invoices(details.InvoiceList);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.PayNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.ClientID.Label);
      this.addColumnHeader(Catalog.ClientAlias);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.PaymentTypeName);
      this.addColumnHeader(Catalog.PaymentValue);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader(Catalog.StatusID);

      if (details.StatusID === Model.StatusID.Void.ID) {
        this.addColumnHeader(Catalog.VoidByID);
        this.addColumnHeader(Catalog.VoidDate);
      }

      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("PayNumber", details, Catalog);
      this.addCellValue("Date", details, Catalog);
      this.addCellValue("ClientID", details, Catalog);
      this.addCellValue("ClientAlias", details, Catalog);
      this.addCellRelatedValue("ClientID", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      this.addCellValue("PaymentTypeName", details, Catalog);
      this.addCellValue("PaymentValue", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellStatus(details.StatusID, Model.StatusID);

      if (details.StatusID === Model.StatusID.Void.ID) {
        this.addCellRelatedValue("VoidByID", details, Catalog);
        this.addCellValue("VoidDate", details, Catalog);
      }

      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
    },
    addDetails_Invoices(invoiceList) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Section.InvoiceList.Title);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(RcpyInvCatalog.InvoiceDate);
      this.addColumnHeader_ID(RcpyInvCatalog.InvoiceID.Label);
      this.addColumnHeader(RcpyInvCatalog.InvoiceID);
      this.addColumnHeader_ID(RcpyInvCatalog.ClientID.Label);
      this.addColumnHeader(RcpyInvCatalog.ClientID);
      this.addColumnHeader_ID(RcpyInvCatalog.WarehouseID.Label);
      this.addColumnHeader(RcpyInvCatalog.WarehouseID);
      this.addColumnHeader(RcpyInvCatalog.PaymentValue);

      // value
      for (const row of invoiceList) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("InvoiceDate", row, RcpyInvCatalog);
        this.addCellValue("InvoiceID", row, RcpyInvCatalog);
        this.addCellRelatedValue("InvoiceID", row, RcpyInvCatalog);
        this.addCellValue("ClientID", row, RcpyInvCatalog);
        this.addCellRelatedValue("ClientID", row, RcpyInvCatalog);
        this.addCellValue("WarehouseID", row, RcpyInvCatalog);
        this.addCellRelatedValue("WarehouseID", row, RcpyInvCatalog);
        this.addCellValue("PaymentValue", row, RcpyInvCatalog);
      }
    }
  }
}
</script>