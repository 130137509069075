export default {
  Activate: {
    Name: "Aktivasi",
    FullName: "Aktivasi",
    Attr: "Act_Activate",
    Code: "Activate",
    Path: "activate",
    Target: "_blank",
    Icon: "mdi mdi-cog-refresh-outline mdi-18px"
    // ShortFileName: "A"
  },
  Deactivate: {
    Name: "Non-Aktif",
    FullName: "Non-Aktif",
    Attr: "Act_Deactivate",
    Code: "Deactivate",
    Path: "deactivate",
    Target: "_blank",
    Icon: "mdi mdi-cog-off-outline mdi-18px"
    // ShortFileName: "DA"
  },
  Delete: {
    Name: "Hapus",
    FullName: "Hapus",
    Attr: "Act_Delete",
    Code: "Delete",
    Class: "delete",
    Path: "delete",
    Target: "_blank",
    Icon: "mdi mdi-delete mdi-18px"
    // ShortFileName: "DL"
  },
  Details: {
    Name: "Rincian",
    FullName: "Rincian",
    Attr: "Act_Details",
    Code: "Details",
    Class: "details",
    Path: "details",
    Target: "_blank",
    Icon: "mdi mdi-magnify mdi-18px"
    // ShortFileName: "D"
  },
  Edit: {
    Name: "Ubah",
    FullName: "Ubah",
    Attr: "Act_Edit",
    Code: "Edit",
    Class: "edit",
    Path: "edit",
    Target: "_blank",
    Icon: "mdi mdi-pencil mdi-18px"
    // ShortFileName: "E"
  },
  EditDate: {
    Name: "Edit Tanggal",
    FullName: "Edit Tanggal",
    Attr: "Act_EditDate",
    Code: "EditDate",
    Class: "editdate",
    Path: "edit-date",
    Target: "_blank",
    Icon: "mdi mdi-pencil mdi-18px"
    // ShortFileName: "ED"
  },
  EditPassword: {
    Name: "Edit Password",
    FullName: "Edit Password",
    Attr: "Act_EditPassword",
    Code: "EditPassword",
    Class: "editpassword",
    Path: "edit-password",
    Target: "_blank",
    Icon: "mdi mdi-pencil mdi-18px"
    // ShortFileName: "EPwd"
  },
  EditPin: {
    Name: "Edit PIN",
    FullName: "Edit PIN",
    Attr: "Act_EditPin",
    Code: "EditPin",
    Path: "edit-pin",
    Target: "_blank",
    Icon: "mdi mdi-pencil mdi-18px"
    // ShortFileName: "EP"
  },
  EditStatus: {
    Name: "Edit Status",
    FullName: "Edit Status",
    Attr: "Act_EditStatus",
    Code: "EditStatus",
    Class: "editstatus",
    Path: "edit-status",
    Target: "_blank",
    Icon: "mdi mdi-pencil mdi-18px"
    // ShortFileName: "ES"
  },
  Export: {
    Name: "Export",
    FullName: "Export",
    FileName: "",
    Attr: "Act_Export",
    Code: "Export",
    Class: "export",
    Path: "export",
    Target: "_blank",
    Icon: "mdi mdi-download mdi-18px"
    // ShortFileName: "Ex"
  },
  ExportDetails: {
    Name: "Export",
    FullName: "Export Rincian",
    FileName: "Rincian",
    Attr: "Act_ExportDetails",
    Code: "ExportDetails",
    Class: "exportdetails",
    Path: "export-details",
    Target: "_blank",
    Icon: "mdi mdi-download mdi-18px"
    // ShortFileName: "ExD"
  },
  ExportList: {
    Name: "Export",
    FullName: "Export Daftar",
    FileName: "Daftar",
    Attr: "Act_ExportList",
    Code: "ExportList",
    Class: "exportlist",
    Path: "export-list",
    Target: "_blank",
    Icon: "mdi mdi-download mdi-18px"
    // ShortFileName: "ExL"
  },
  List: {
    Name: "Daftar",
    FullName: "Daftar",
    Attr: "Act_List",
    Code: "List",
    Class: "list",
    Path: null,
    Target: "_blank",
    Icon: "mdi mdi-format-list-bulleted mdi-18px"
    // ShortFileName: "L"
  },
  New: {
    Name: "Tambah",
    FullName: "Tambah",
    Attr: "Act_New",
    Code: "New",
    Class: "new",
    Path: "new",
    Target: "_blank",
    Icon: "mdi mdi-plus-thick mdi-18px"
    // ShortFileName: "N"
  },
  Print: {
    Name: "Print",
    FullName: "Print",
    Attr: "Act_Print",
    Code: "Print",
    Class: "print",
    Path: "print",
    Target: "_blank",
    Icon: "mdi mdi-printer mdi-18px"
    // ShortFileName: "P"
  },
  PrintBarcode: {
    Name: "Print Barcode",
    FullName: "Print Barcode",
    Attr: "Act_PrintBarcode",
    Code: "PrintBarcode",
    Class: "printbarcode",
    Path: "print-barcode",
    Target: "_blank",
    Icon: "mdi mdi-printer mdi-18px"
    // ShortFileName: "PB"
  },
  PrintDetails: {
    Name: "Print",
    FullName: "Print",
    Attr: "Act_PrintDetails",
    Code: "PrintDetails",
    Class: "printdetails",
    Path: "print-details",
    Target: "_blank",
    Icon: "mdi mdi-printer mdi-18px"
    // ShortFileName: "PD"
  },
  PrintFaktur: {
    Name: "Print Faktur",
    FullName: "Print Faktur",
    Attr: "Act_PrintFaktur",
    Code: "PrintFaktur",
    Class: "printfaktur",
    Path: "print-faktur",
    Target: "_blank",
    Icon: "mdi mdi-printer mdi-18px"
    // ShortFileName: "PF"
  },
  PrintItems: {
    Name: "Print Barang",
    FullName: "Print Barang",
    Attr: "Act_PrintItems",
    Code: "PrintItems",
    Class: "printitems",
    Path: "print-items", 
    Target: "_blank", 
    Icon: "mdi mdi-printer mdi-18px"
    // ShortFileName: "PI"
  },
  PrintList: {
    Name: "Print",
    FullName: "Print Daftar",
    Attr: "Act_PrintList",
    Code: "PrintList",
    Class: "printlist",
    Path: "print-list",
    Target: "_blank",
    Icon: "mdi mdi-printer mdi-18px"
    // ShortFileName: "PL"
  },
  PrintPicking: {
    Name: "Print Pengambilan Barang",
    FullName: "Print Pengambilan Barang",
    Attr: "Act_PrintPicking",
    Code: "PrintPicking",
    Class: "printpicking",
    Path: "print-picking",
    Target: "_blank",
    Icon: "mdi mdi-printer mdi-18px"
    // ShortFileName: "PP"
  },
  PrintSJ: {
    Name: "Print Surat Jalan",
    FullName: "Print Surat Jalan",
    Attr: "Act_PrintSJ",
    Code: "PrintSJ",
    Class: "printsj",
    Path: "print-sj",
    Target: "_blank",
    Icon: "mdi mdi-printer mdi-18px"
    // ShortFileName: "PSJ"
  },
  PrintThermal: {
    Name: "Print Thermal",
    FullName: "Print Thermal",
    Attr: "Act_PrintThermal",
    Code: "PrintThermal",
    Class: "printthermal",
    Path: "print-thermal",
    Target: "_blank",
    Icon: "mdi mdi-printer mdi-18px"
    // ShortFileName: "PT"
  },
  Report: {
    Name: "Laporan",
    FullName: "Laporan",
    Attr: "Act_Report",
    Code: "Report",
    Class: "report",
    Path: null,
    Target: "_blank",
    Icon: "mdi mdi-format-list-bulleted mdi-18px"
    // ShortFileName: "R"
  },
  Select: {
    Name: "Pilih",
    FullName: "Pilih",
    Attr: "Act_Select",
    Code: "Select",
    Class: "select",
    Path: "select",
    Target: "_blank",
    Icon: "mdi mdi-gesture-double-tap mdi-18px"
    // ShortFileName: "Se"
  },

  // support for related
  RelatedDetails: {
    Name: "RelatedDetails",
    FullName: "RelatedDetails",
    Attr: "Act_RelatedDetails",
    Code: "RelatedDetails",
    Class: "relateddetails",
    Path: null,
    Icon: "mdi mdi-magnify mdi-18px"
    // ShortFileName: "RD"
  },
  RelatedGlobalInput: {
    Name: "Related Global Input",
    FullName: "Related Global Input",
    Attr: "Act_RelatedGlobalInput",
    Code: "RelatedGlobalInput",
    Class: "relatedglobalinput",
    Path: null,
    Icon: "mdi mdi-magnify mdi-18px"
    // ShortFileName: "ReGI"
  },
  RelatedInput: {
    Name: "Related Input",
    FullName: "Related Input",
    Attr: "Act_RelatedInput",
    Code: "RelatedInput",
    Class: "relatedinput",
    Path: null,
    Icon: "mdi mdi-magnify mdi-18px"
    // ShortFileName: "ReI"
  },
  RelatedList: {
    Name: "RelatedList",
    FullName: "RelatedList",
    Attr: "Act_RelatedList",
    Code: "RelatedList",
    Class: "relatedlist",
    Path: null,
    Icon: "mdi mdi-magnify mdi-18px"
    // ShortFileName: "RL"
  },
  RelatedPrintDetails: {
    Name: "RelatedPrintDetails",
    FullName: "RelatedPrintDetails",
    Attr: "Act_RelatedPrintDetails",
    Code: "RelatedPrintDetails",
    Class: "relatedprintdetails",
    Path: null,
    Icon: "mdi mdi-magnify mdi-18px"
    // ShortFileName: "RPD"
  },
  SearchItem: {
    Name: "Cari",
    FullName: "Cari",
    Attr: "Act_SearchItem",
    Code: "SearchItem",
    Class: "searchitem",
    Path: null,
    Icon: "mdi mdi-magnify mdi-18px"
    // ShortFileName: "ScI"
  },
  SearchList: {
    Name: "Cari",
    FullName: "Cari",
    Attr: "Act_SearchList",
    Code: "SearchList",
    Class: "searchlist",
    Path: null,
    Icon: "mdi mdi-magnify mdi-18px"
    // ShortFileName: "ScL"
  },
  SearchPcs: { // used by: stock search by packaging pcs
    Name: "Cari",
    FullName: "Cari",
    Attr: undefined,
    Code: "SearchPcs",
    Class: "searchpcs",
    Path: null,
    Icon: "mdi mdi-magnify mdi-18px"
    // ShortFileName: "ScP"
  },

  // DEPRECATED
  Search: {
    Name: "Cari",
    FullName: "Cari",
    Attr: "Act_Search",
    Code: "Search",
    Class: "search",
    Path: null,
    Icon: "mdi mdi-magnify mdi-18px"
    // ShortFileName: "Sc"
  }
}