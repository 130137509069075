<template>
  <div>
    <template v-if="showImage && data.ImageSrc !== null">
      <div class="columns">
        <div class="column mt-3 ks-max-width-5r">
          <kst-image size-auto border-off hide-preview read-only thumbnail 
            :src="data.ImageSrc"/>
        </div>
        <div class="column">
          <div class="is-size-3">{{ data.name }}</div>
          <div>{{ data.address }}</div>
          <div>Telp. {{ data.phone }}</div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="is-size-3">{{ data.name }}</div>
      <div>{{ data.address }}</div>
      <div>Telp. {{ data.phone }}</div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    showImage: Boolean
  }
}
</script>