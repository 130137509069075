import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./PurModel.js";

export default {
  Dialog: {
    SearchItem: {
      Class: AppCatalog.ClassPrefix.Module + "-" + Model.Module.Class + "-" +
        Model.PurchaseItem.Module.Class +
        Model.PurchaseItem.ModuleType.SearchList.Class,
      Title: AppModuleType.Search.Name + " " + Model.PurchaseItem.Module.FullName +
        " dari " + Model.Module.FullName
    }
  },
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.Name,
          ReceiveList: AppModuleType.List.Name + " " + AppModule.Receive.FullName
        },
        Section: {
          ReceiveItem: AppModuleType.List.Name + " " + AppModule.Item.FullName + ":"
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          ReceiveList: AppModuleType.List.Name + " " + AppModule.Receive.FullName,
        }
      }
    },
    List: {
      Excel: {
        //file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Purchase.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Purchase.FullName,
          PurchaseItem: "Dengan " + AppModule.Item.FullName,
        }
      }
    }
  },
  Message: {
    Edit_ConfirmDeleteItem:
      "Yakin menghapus " + Model.PurchaseItem.Module.FullName + "?",
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.PurchaseItem.Module.FullName + "?"
  },
  Section: {
    Details: {
      Title: AppModuleType.Details.FullName
    },
    Delivery: {
      Title: "Info Pengiriman"
    },
    PO: {
      Title: "Info " + Model.Module.FullName,
      AddItem: {
        Title: "Daftar Barang " + Model.Module.FullName
      }
    },
    Receive: {
      Title: AppModule.Receive.FullName
    },
    RtnReceive: {
      Title: AppModule.RtnReceive.FullName
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name,
    ReceiveList: AppModuleType.List.Name + " " + AppModule.Receive.FullName
  }
}