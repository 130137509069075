import App from "../../services/App/App.js";
import Catalog from "./PurCatalog.js";
import ModelBody from "./PurModel_Body.js";
import ModelDelete from "./PurModel_Delete.js";
import ModelDetails from "./PurModel_Details.js";
import ModelEdit from "./PurModel_Edit.js";
import ModelNew from "./PurModel_New.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  PurchaseItem: ModelBody.PurchaseItem,
  Item: ModelBody.Item,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  NewItem: ModelBody.NewItem,
  Search: ModelBody.Search,
  Status: ModelBody.Status,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createItem() {
    return ModelBody.createItem();
  },
  createStatusOptions(optionAllText) {
    return ModelBody.createStatusOptions(optionAllText, ModelBody.Status);
  },

  setDetailsByStatus(userData) {
    ModelBody.setDetailsByStatus(userData, ModelBody.Status);
  },
  setDetailsByVendor(userData, vendorData) {
    ModelBody.setDetailsByVendor(userData, vendorData);
  },
  setDetailsByWarehouse(userData, warehouseOptions) {
    ModelBody.setDetailsByWarehouse(userData, warehouseOptions);
  },

  setItemErrors(userItem, field, errors) {
    userItem.Errors = App.Vee.getRowErrors(userItem, field, errors);
  },
  setItemByNewItem(userItem, itemName) {
    ModelBody.setItemByNewItem(userItem, itemName, ModelBody.NewItem);
  },
  setItemByPrice(userItem, priceData) {
    ModelBody.setItemByPrice(userItem, priceData);
  },
  setItemByStock(userItem, itemData) {
    ModelBody.setItemByStock(userItem, itemData);
  },
  setItemByVendorItem(userItem, vendorItemData) {
    ModelBody.setItemByVendorItem(userItem, vendorItemData);
  },

  clearDetailsByVendor(userData) {
    ModelBody.clearDetailsByVendor(userData);
  },

  updateDetails(userData, userItems) {
    ModelBody.updateDetails(userData, userItems);
  },
  updateItem(userItem) {
    ModelBody.updateItem(userItem);
  },
  updateItemByPackaging(userItem) {
    ModelBody.updateItemByPackaging(userItem);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  validationDeliveryDate(userData) {
    return userData.DeliveryDate === null
      ? {}
      : {
        [App.Vee.Rule.MinDate]: {
          target: userData.Date,
          label: Catalog.Date.Label
        }
      };
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Delete */
  Delete: {
    getMessageConfirmationSection(receiveList, rtnReceiveList) {
      return ModelDelete.getMessageConfirmationSection(
        receiveList, rtnReceiveList
      );
    },
    getPurchaseSectionHelp(purchaseData) {
      return ModelDelete.getPurchaseSectionHelp(purchaseData);
    },
    getReceiveSectionHelp(receiveList) {
      return ModelDelete.getReceiveSectionHelp(receiveList);
    },
    getRtnReceiveSectionHelp(rtnReceiveList) {
      return ModelDelete.getRtnReceiveSectionHelp(rtnReceiveList);
    },
    validationPONumber(purchaseData) {
      return ModelDelete.validationPONumber(purchaseData);
    }
  },

  /*** method - Details ***/

  Details: {
    getItemInfo(item) {
      return ModelDetails.getItemInfo(item, ModelBody.NewItem);
    }
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByData(userData, purchaseData, warehouseList, receiveCount) {
      ModelEdit.setDetailsByData(userData, purchaseData, warehouseList,
        receiveCount
      );
    },

    setItemsByData(userItems, purchaseItems) {
      ModelEdit.setItemsByData(userItems, purchaseItems);
    },

    getItemInfo(userItem) {
      return ModelEdit.getItemInfo(userItem, ModelBody.NewItem);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit ||
        access.Act_PrintDetails ||
        access.Act_ExportDetails;
    }
  },

  /*** method - New ***/

  New: {
    getItemInfo(userItem) {
      return ModelNew.getItemInfo(userItem, ModelBody.NewItem);
    },
    setItemsByVendorItem(userItems, vendorItemList, packagingList) {
      ModelNew.setItemsByVendorItem(userItems, vendorItemList, packagingList);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },
  getWarehouseDefault(items, defaultId) {
    return WarehouseModel.getDefault(items, defaultId);
  }
}