import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import PurEnum from "./PurEnum.js";
import ItemModel from "../Item/ItemModel.js";

export default {
  // required in model
  Module: AppModule.Purchase,
  ModuleType: {
    Delete: AppModuleType.Delete,
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    SearchList: AppModuleType.SearchList
  },

  /*** related ***/

  PurchaseItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      SearchList: AppModuleType.SearchList
    }
  },

  // other
  Item: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      Select: AppModuleType.Select
    }
  },

  /*** property ***/

  Access: {
    Act_Delete: AppModuleAttr.Purchase_Act_Delete,
    Act_Details: AppModuleAttr.Purchase_Act_Details,
    Act_Edit: AppModuleAttr.Purchase_Act_Edit,
    Act_ExportDetails: AppModuleAttr.Purchase_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.Purchase_Act_ExportList,
    Act_List: AppModuleAttr.Purchase_Act_List,
    Act_New: AppModuleAttr.Purchase_Act_New,
    Act_PrintDetails: AppModuleAttr.Purchase_Act_PrintDetails,
    Fld_Amount: AppModuleAttr.Purchase_Fld_Amount,
    // related
    Receive_Fld_Amount: AppModuleAttr.Receive_Fld_Amount,
    Receive_Act_List: AppModuleAttr.Receive_Act_List,
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails,
      AppModuleType.Delete
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails,
      AppModuleType.Delete
    ]
  },

  NewItem: PurEnum.NewItem,

  Search: {
    ListFields: ["PONumber"],
    ListParams: ["Search", "Date", "Status", "Vendor", "VendorID",
      "WarehouseID"],
    SearchListFields: ["PONumber"]
  },

  Status: PurEnum.Status,

  /*** method ***/

  createDetails() {
    return {
      ID: null,
      // user input
      PONumber: "",
      IsAutoNumber: true,
      Date: App.In.getDateToday(),
      VendorID: null,
      Vendor: "",
      VendorAddress: "",
      WarehouseID: null,
      Comment: "",
      DeliveryAddress: "",
      DeliveryDate: App.In.getDateToday(),
      // by system
      VendorAlias: "",
      VendorMobile: "",
      VendorPhone: "",
      VendorEmail: "",
      ReceivingCount: 0,
      // computed field
      Total: 0
    };
  },
  createItem() {
    return {
      ID: null,
      // user input
      DispatchID: null,
      Name: "",
      Quantity: "",
      PackagingName: "",
      BuyPrice: "",
      IsClosed: false,
      // defined by system
      SKU: "",
      PackagingValue: 1,
      DispatchIsActive: true,
      PackagingOptions: null,
      QtyReceive: 0,
      // computed field
      Packaging: "",
      TotalQuantity: 0,
      ExpenseAmount: 0,
      // error
      Errors: [],
      ErrorsColl: {},
      // validation
      CustomValidations: {}
    }
  },
  createStatusOptions(optionAllText, statusEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: statusEnum.Open.ID, [rowLabel]: statusEnum.Open.Label },
      { [rowId]: statusEnum.Closed.ID, [rowLabel]: statusEnum.Closed.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },

  setDetailsByStatus(userData, statusEnum) {
    userData.StatusName = App.Data.getStatusLabel(userData.Status, statusEnum);
  },
  setDetailsByVendor(userData, vendorData) {
    // user input
    userData.VendorID = vendorData.ID;
    userData.Vendor = App.In.getString(vendorData.Name);
    userData.VendorAddress = App.In.getString(vendorData.Address);
    // by system
    userData.VendorAlias = vendorData.Alias;
    userData.VendorMobile = App.In.getString(vendorData.ContactMobile);
    userData.VendorPhone = App.In.getString(vendorData.ContactPhone);
    userData.VendorEmail = App.In.getString(vendorData.ContactEmail);
  },
  setDetailsByWarehouse(userData, warehouseOptions) {
    userData.DeliveryAddress = App.Array.searchValue(
      warehouseOptions.rows, "ID", userData.WarehouseID, "Address"
    );
  },

  setItemByNewItem(userItem, itemName, newItemDefault) {
    // user input
    userItem.DispatchID = newItemDefault.ItemID;
    userItem.Name = App.In.getString(itemName);
    userItem.PackagingName = newItemDefault.PackagingName;
  },
  setItemByPrice(userItem, priceData) {
    // user input
    userItem.PackagingName = priceData.PackagingName;
    userItem.BuyPrice = App.In.getInteger(priceData.BuyPrice);
    // by system
    userItem.PackagingValue = priceData.PackagingValue;
  },
  setItemByStock(userItem, itemData) {
    // user input
    userItem.DispatchID = itemData.ID;
    userItem.Name = itemData.Name;
    // by system
    userItem.SKU = itemData.SKU;
    userItem.PackagingOptions = ItemModel.createPackagingOptions(
      itemData.PackagingList
    );

    const packaging = userItem.PackagingOptions.rows[0];
    if (packaging) {
      userItem.PackagingName = packaging.Name;
      userItem.PackagingValue = packaging.Qty;
    }
  },
  setItemByVendorItem(userItem, vendorItemData) {
    // user input
    userItem.DispatchID = App.In.getInteger(vendorItemData.ItemID);
    userItem.Name = App.In.getString(vendorItemData.Name);
    userItem.Quantity = App.In.getInteger(vendorItemData.Qty);
    userItem.BuyPrice = App.In.getInteger(vendorItemData.BuyPrice);
    // by system
    userItem.SKU = App.In.getString(vendorItemData.SKU);
    userItem.PackagingOptions = ItemModel.createPackagingOptions(
      vendorItemData.PackagingList
    );
    userItem.PackagingName = App.In.getString(vendorItemData.PackagingName);
    userItem.PackagingValue = App.In.getInteger(vendorItemData.PackagingValue);
  },

  clearDetailsByVendor(userData) {
    // user input
    userData.VendorID = null;
    userData.Vendor = "";
    userData.VendorAddress = "";
    // by system
    userData.VendorAlias = "";
    userData.VendorMobile = "";
    userData.VendorPhone = "";
    userData.VendorEmail = "";
  },

  updateDetails(userData, userItems) {
    let total = 0;
    for (const item of userItems) {
      total += item.ExpenseAmount;
    }

    userData.Total = total;
  },
  updateItem(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
    userItem.TotalQuantity = App.Data.getTotalQty(
      userItem.Quantity, userItem.PackagingValue
    );
    userItem.ExpenseAmount = App.Data.getTotal(
      userItem.Quantity, userItem.BuyPrice
    );
  },
  updateItemByPackaging(userItem) {
    if (userItem.PackagingOptions) {
      const packagingData = ItemModel.getPackagingData(
        userItem.PackagingOptions.rows, userItem.PackagingName
      );

      if (packagingData) {
        // defined by system
        userItem.PackagingValue = packagingData.Qty;
      }
    }
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      WarehouseID: userData.WarehouseID,
      PONumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.PONumber),
      Date: App.Out.getDateString(userData.Date),
      VendorID: userData.VendorID,
      Vendor: App.Out.getString(userData.Vendor),
      VendorAddress: App.Out.getString(userData.VendorAddress),
      VendorMobile: App.Out.getString(userData.VendorMobile),
      VendorPhone: App.Out.getString(userData.VendorPhone),
      VendorEmail: App.Out.getString(userData.VendorEmail),
      DeliveryAddress: App.Out.getString(userData.DeliveryAddress),
      DeliveryDate: App.Out.getDateString(userData.DeliveryDate),
      Comment: App.Out.getString(userData.Comment)
    };
  },
  populateItems(userItems) {
    let resultItems = [];

    for (const item of userItems) {
      resultItems.push({
        ID: item.ID,
        DispatchID: item.DispatchID,
        Name: App.Out.getString(item.Name),
        PackagingName: item.PackagingName,
        PackagingValue: item.PackagingValue,
        Quantity: App.Out.getInteger(item.Quantity),
        BuyPrice: App.Out.getInteger(item.BuyPrice),
        IsClosed: App.Out.getBoolean(item.IsClosed)
      });
    }
    return resultItems;
  }
}